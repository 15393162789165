import React, { useContext, useState } from "react";
import HomeHeader from "../Component/HomeHeader";
import QuestionImage from "../images/question.svg";
import MinusImg from "../images/minus.svg";
import PlusImg from "../images/plus.svg";
import Question from "../Component/reusableCompo/Question";
import HomeFooter from "../Component/HomeFooter";
import GlobalStorage from "../Storage/ContextProvider";
const faqItem = [
  {
    title: "What languages does MedInclude support?",
    desc: "MedInclude currently supports 12 languages (and counting), including Spanish, French, Arabic, Amharic, and more. We’re continually expanding our language offerings to ensure inclusivity.",
  },
  {
    title: "Is MedInclude HIPAA-compliant?",
    desc: "Yes, MedInclude is fully HIPAA-compliant. We prioritize data privacy and security to ensure patient and provider information remains safe and confidential.",
  },
  {
    title: "Can MedInclude integrate with our existing systems?",
    desc: "Yes, MedInclude can integrate with your Electronic Medical Records (EMR) or Electronic Health Records (EHR) systems. Contact us to learn more about customization options for your organization.",
  },
  {
    title: "How accurate are MedInclude’s translations?",
    desc: "Our platform is built using healthcare-specific data and AI, rigorously tested for accuracy. We are continuously developing our model to ensure the highest levels of accuracy and reliability.",
  },

  {
    title: "How can I get started with MedInclude?",
    desc: "You can get started by booking a demo or signing up on our website to explore how MedInclude can work for you.",
  },

  {
    title: "Why choose MedInclude over other platforms?",
    desc: "Unlike generic translation tools, MedInclude is specifically designed for healthcare. We simplify medical jargon and provide accurate translations tailored to healthcare settings, ensuring better patient understanding and outcomes.",
  },

  {
    title:
      "Can patients access their medical information directly through MedInclude?",
    desc: "Yes! MedInclude allows providers to share simplified and translated medical information directly with patients via a secure patient portal.",
  },
  {
    title: "Does MedInclude work for non-English speakers only?",
    desc: "No. MedInclude benefits all patients, including English speakers, by simplifying complex medical jargon into plain, understandable language.",
  },

  {
    title: "What input methods does MedInclude support?",
    desc: "MedInclude supports text, voice, and document uploads (e.g., PDFs). This flexibility ensures providers can use the platform in a way that best suits their needs.",
  },

  {
    title: "Does MedInclude require special training to use?",
    desc: "Not at all! MedInclude’s intuitive design makes it easy for healthcare providers to start simplifying and translating medical information right away.",
  },

  {
    title: "What devices can I use MedInclude on?",
    desc: "MedInclude is a web-based platform accessible from any internet-enabled device, including computers, tablets, and smartphones.",
  }
];
const Faqs = () => {
  const [activeFaq, setActiveFaq] = useState(0);
  const [planSelected, setPlanSelected] = useState("monthly");
  const [selectedOption, setSelectedOption] = useState("free");
  const { activeMobileNav } = useContext(GlobalStorage);

  return (
    <>
      <HomeHeader activeLink="resources" />
      <div
        className={`${
          activeMobileNav ? "max-h-[calc(100vh-90px)] overflow-hidden" : ""
        }`}
      >
        <div className="px-[100px] max-[1300px]:px-[70px] max-[750px]:px-[40px]  py-[60px] font-poppins">
          <div className="flex w-full justify-between max-[1024px]:flex-col ">
            <div className="w-[35%]  max-[1024px]:w-full relative">
              <h3 className="text-[22px] leading-[33px] text-primary">FAQS</h3>
              <h2 className="text-[50px] leading-[75px]  font-semibold w-[70%]  max-[1024px]:text-[32px] max-[1024px]:leading-[57px] max-[750px]:text-[28px] max-[750px]:leading-[45px]">
                FREQUENTLY ASKED <span className="text-primary">QUESTIONS</span>
              </h2>
              <img
                src={QuestionImage}
                className="absolute max-[750px]:w-[120px] max-[750px]:h-[120px] top-[110px]  max-[1024px]:top-0 max-[1350px]:-right-[75px] right-0 w-[250px] h-[250px] max-[1024px]:right-0  max-[1024px]:w-[150px]  max-[1024px]:h-[150px]"
                alt=""
              />
            </div>

            <div className="w-[50%]  max-[1024px]:w-full">
              <div className="my-9  ">
                {faqItem?.map((item, index) => (
                  <>
                    <div className="my-11">
                      <div className="flex justify-between items-center">
                        <p className="text-[20px] leading-[30px] font-[500] text-black">
                          {item?.title}
                        </p>
                        {activeFaq === index ? (
                          <img
                            src={MinusImg}
                            className="cursor-pointer"
                            alt=""
                            onClick={() => setActiveFaq(index)}
                          />
                        ) : (
                          <img
                            src={PlusImg}
                            className="cursor-pointer"
                            alt=""
                            onClick={() => setActiveFaq(index)}
                          />
                        )}
                      </div>
                      {activeFaq === index && (
                        <>
                          <div>
                            <p className="text-sm leading-[21px] text-grey mt-6 pr-7 max-[750px]:pr-0">
                              {item?.desc}
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>

          <Question />
        </div>
        <HomeFooter />
      </div>
    </>
  );
};

export default Faqs;
