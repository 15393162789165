import React, { useContext, useState } from "react";
import HomeHeader from "../Component/HomeHeader";
import ContactImg from "../images/contactNew.svg";

import HomeFooter from "../Component/HomeFooter";
import QuestionImage from "../images/question.svg";
import { FaArrowRightLong } from "react-icons/fa6";
import GlobalStorage from "../Storage/ContextProvider";
import { toast } from "react-toastify";
import axios from "axios";
import BASE_URL from "../baseUrl";
import { Link } from "react-router-dom";

const Contact = () => {
  const { activeMobileNav } = useContext(GlobalStorage);
  const [email, setEmail] = useState("");
  const [detail, setDetail] = useState("");
  const [headLine, setHeadLine] = useState("");

  const handleConfirmRequest = async () => {
    if (!email) {
      return toast.error("Please Enter the Email Address");
    }
    if (!detail) {
      return toast.error("Please Enter Detail Field");
    }
    if (!headLine) {
      return toast.error("Please Enter HeadLine Field");
    }
    const data = {
      headLine: headLine,
      emailId: email,
      detail: detail,
    };

    const requestDemo = await axios({
      method: "POST",
      url: `${BASE_URL}/contactusform-new`,
      data: data,
    })
      .then((response) => {
        console.log("Contact form success", response);
        toast.success(response?.data);
      })
      .catch((error) => {
        console.log("Hospital Update  Error", error.response.data);
        toast.error(error?.response?.data);
      });
  };

  return (
    <>
      <HomeHeader activeLink="contact" />
      <div
        className={`${
          activeMobileNav ? "max-h-[calc(100vh-90px)] overflow-hidden" : ""
        }`}
      >
        <div className="pt-[40px] px-[100px]  max-[1300px]:px-[70px] max-[750px]:px-[40px] mb-[80px] font-poppins">
          <div className="flex gap-24 max-[750px]:flex-col-reverse ">
            <div className="w-[55%] max-[750px]:w-full">
              <h2 className="text-[50px] leading-[75px] max-[1024px]:text-[35px] max-[1024px]:leading-[60px] max-[750px]:text-[28px] max-[750px]:leading-[45px]  font-semibold text-black ">
                Have a question? <br />
                We are happy to help
              </h2>

              <div className="py-8 text-black">
                <div className="my-5">
                  <p className="font-[500] text-[20px] leading-[30px]">
                    Email Address
                  </p>
                  <input
                    type="text"
                    className="outline-none w-full border-[2px] rounded-[10px] border-[#EFEBEB] h-[60px] px-3 mt-2"
                    placeholder="johndoe@gmail.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <div className="my-5 ">
                  <p className="font-[500] text-[20px] leading-[30px]">
                    Headline
                  </p>
                  <input
                    type="text"
                    className="outline-none w-full border-[2px] rounded-[10px] border-[#EFEBEB] h-[60px] px-3 mt-2"
                    placeholder="Ask your Question"
                    value={headLine}
                    onChange={(e) => setHeadLine(e.target.value)}
                  />
                </div>

                <div className="my-5 ">
                  <p className="font-[500] text-[20px] leading-[30px]">
                    Details
                  </p>
                  <input
                    type="text"
                    className="outline-none w-full border-[2px] rounded-[10px] border-[#EFEBEB] h-[60px] px-3 mt-2"
                    placeholder="Explain In Detail"
                    value={detail}
                    onChange={(e) => setDetail(e.target.value)}
                  />
                </div>
              </div>

              <button
                className="w-full bg-primary font-bold text-[20px] leading-[30px] text-white h-[60px] rounded-[10px]"
                onClick={handleConfirmRequest}
              >
                Submit
              </button>
            </div>

            <div className="w-[45%] max-[750px]:w-full flex  items-end max-[750px]:items-center flex-col">
              <div className="relative">
                <div className="">
                  <img src={ContactImg} className="z-20" alt="" />
                </div>
              </div>
              <div>
                <div className="flex items-center font-semibold font-poppins mt-5 max-[1024px]:flex-col">
                  <img
                    src={QuestionImage}
                    className="max-[1024px]:w-[30%]"
                    alt=""
                  />
                  <div className="text-[25px] max-[1024px]:text-[22px] max-[1024px]:leading-[35px] leading-[45px]">
                    <h3>Checkout some of our</h3>
                    <Link
                      to="/resources/faqs"
                      className="flex items-center gap-3 text-[#515A9C]"
                    >
                      <h3>Frequently asked Questions</h3>
                      <FaArrowRightLong />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <HomeFooter />
      </div>
    </>
  );
};

export default Contact;
