import React from "react";
import AboutQuestion from "../../images/landing/aboutQuestion.svg";
import { FaArrowRightLong } from "react-icons/fa6";
import { Link } from "react-router-dom";
const Question = () => {
  return (
    <div>
      <div className="mt-[80px] w-full flex max-[1024px]:gap-4  max-[750px]:flex-col">
        <div className="w-[50%] max-[750px]:w-full max-[1024px]:w-[60%]">
          <h2 className="text-[50px] max-[1024px]:text-[35px] max-[1024px]:leading-[61px] max-[750px]:text-[30px]  leading-[75px] font-semibold ">
            Have a question?
          </h2>
          <Link
            to="/contact"
            className=" mt-12 max-[1024px]:mt-6 w-[475px] max-[1024px]:w-[70%] h-[60px] bg-primary rounded-[10px] text-white text-[20px] leading-[30px] font-bold flex items-center justify-center gap-2 "
          >
            <span>Contact Us </span>
            <FaArrowRightLong />
          </Link>
        </div>

        <div className="w-[50%] max-[750px]:w-full max-[1024px]:w-[40%] flex justify-end max-[750px]:mt-9">
          <img src={AboutQuestion} className="w-full" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Question;
