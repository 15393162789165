import React from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import BlogCuriousImg from "../../images/blogCurious.png";
import { Link } from "react-router-dom";

const BlogCurious = () => {
  return (
    <div
      className="mb-[80px] w-full h-[219px] max-[750px]:h-[270px]  flex justify-center items-center"
      style={{
        background:
          "linear-gradient(270deg, rgba(149, 164, 252, 0.1) 0%, rgba(255, 86, 86, 0.1) 100%)",
      }} 

    >
      <div className="flex items-center gap-4 font-semibold font-poppins max-[750px]:flex-col">
        <img src={BlogCuriousImg} alt="" />
        <div className="text-[30px] leading-[45px] max-[750px]:text-xl">
          <h3>Curious to know more?</h3>
          <Link to="/about" className="flex items-center gap-3 text-[#515A9C]">
            <h3 className="underline">Read more About Us</h3>
            <FaArrowRightLong />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BlogCurious;
