import React, { useState, useEffect, useContext } from "react";
import DoctorSidebar from "../../Component/Doctor/Layout/DoctorSidebar";
import { PiNotepadBold } from "react-icons/pi";
import Modal from "react-modal";
import PatientImage from "../../images/patientImage.png";
import { CiSearch } from "react-icons/ci";
import { PiUploadFill } from "react-icons/pi";
import { RiEdit2Fill } from "react-icons/ri";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ReactFileReader from "react-file-reader";
import axios from "axios";
import BASE_URL from "../../baseUrl";
import { isAuth } from "../../auth/helper";
import GlobalStorage from "../../Storage/ContextProvider";
import moment from "moment";
import { FaRegUserCircle } from "react-icons/fa";
import { toast } from "react-toastify";
import { MdEdit, MdEmail } from "react-icons/md";
import Popup from "reactjs-popup";
import { EmailShareButton, WhatsappShareButton } from "react-share";

const DoctorDashboardNew = () => {
  const params = useParams();
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",

      opacity: 1,
      transform: "translate(-50%, -50%)",
    },
  };
  const patientData = [
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
    {
      id: 4,
    },
    {
      id: 5,
    },
  ];

  const language = [
    {
      value: "",
      name: "Select Language",
    },
    {
      value: "pa",
      name: "Punjabi ",
    },
    {
      value: "es",
      name: "Spanish",
    },
    {
      value: "pt",
      name: "Portuguese (Brazil)",
    },

    {
      value: "ar",
      name: "Arabic",
    },
    {
      value: "de",
      name: "German",
    },
    {
      value: "hi",
      name: "Hindi",
    },
    {
      value: "sr",
      name: "Serbian",
    },
    {
      value: "ur",
      name: "Urdu",
    },
    {
      value: "fr",
      name: "French",
    },
    {
      value: "so",
      name: "Somali",
    },
    {
      value: "uk",
      name: "Ukrainian",
    },
    {
      value: "zh-TW",
      name: "Chinese (Traditional)",
    },

    {
      value: "am",
      name: "Amharic",
    },
  ];

  const navigate = useNavigate();
  const [getPatientReport, setGetPatientReport] = useState();
  const [fileContent, setFileContent] = useState("");
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [automatedFile, setAutomatedFile] = useState(null);
  const [processedDocumentData, setProcessedDocumentData] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [disableSelect, setDisableSelect] = useState(true);

  // const [allPatientsData, setAllPatientsData] = useState();
  const [transcribingText, setTranscribingText] = useState("Transcribe");
  const [transCribeClick, setTranscribeClick] = useState(false);
  const [patientName, setPatientName] = useState("");
  const [editPopup, setEditPopup] = useState(false);
  const {
    rawData,
    setRawData,
    processedData,
    setProcessedData,
    patients,
    setPatients,
    patientDetail,
    setPatientDetail,
    addPatient,
    setAddPatient,
    selectPatient,
    setSelectPatient,
    doctorPatient,
    setDoctorPatient,
    doctorProfile,
    setDoctorProfile,
    refreshPatient,
    setRefreshPatient,
  } = useContext(GlobalStorage);

  // useEffect(() => {
  //   loadAllPatient();

  // }, []);
  useEffect(() => {
    setPatientName(patientDetail?.name);
  }, [patientDetail]);
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const handleDocumentProcessing = (action) => {
    if (action == "translate") {
      setLoading2(true);
    } else {
      setLoading(true);
    }

    setTranscribeClick(true);
    const formData = new FormData();
    formData.append("file", automatedFile);
    formData.append("doctorId", isAuth()?._id);
    formData.append("patientId", patientDetail?._id);
    formData.append("language", selectedLanguage);
    formData.append("action", action);
    axios
      .post(`${BASE_URL}/doc`, formData)
      .then((response) => {
        console.log("Translate Success", response);

        setLoading(false);
        setLoading2(false);
        setProcessedDocumentData(response?.data);
        // navigate(`/doctor/transcribe/${params?.patientId}`);
        // navigate(`/doctor/transcribe/${patientDetail?._id}`, {
        //   state: {
        //     rawData: response?.data?.report?.original,
        //     processedData: response?.data?.report?.transcribe,
        //   },
        // });
        // setTranslatedData(response.data.translatedText);
      })
      .catch((error) => {
        setAutomatedFile(null);
        setLoading(false);
        setLoading2(false);
        console.log("Transcribe  Error", error);
        return toast.error("Unable to process the file at this time.");
      });
  };
  const handletranscribe = (event) => {
    setTranscribingText("Transcribing...");
    setTranscribeClick(true);
    const formData = new FormData();
    formData.append("medReportFile", file);
    formData.append("doctorId", isAuth()?._id);
    formData.append("patientId", patientDetail?._id);
    formData.append("medTextReport", "");
    axios
      .post(`${BASE_URL}/transcribe`, formData)
      .then((response) => {
        console.log("Translate Success", response);
        setFile(null);
        setTranscribingText("Transcribe");
        setTranscribeClick(false);
        setRawData(response?.data?.report?.original);
        setProcessedData(response?.data?.report?.transcribe);
        // navigate(`/doctor/transcribe/${params?.patientId}`);
        navigate(`/doctor/transcribe/${patientDetail?._id}`, {
          state: {
            rawData: response?.data?.report?.original,
            processedData: response?.data?.report?.transcribe,
          },
        });
        // setTranslatedData(response.data.translatedText);
      })
      .catch((error) => {
        setFile(null);
        setTranscribingText("Transcribe");
        setTranscribeClick(false);
        console.log("Transcribe  Error", error);
      });
  };

  const handleChange = (event) => {
    if (doctorProfile?.status === "InActive") {
      return toast.error("Account Deactivated");
    }
    setFile(event.target.files[0]);
    // console.log("LOGG", event.target.files[0]);
  };

  const handleChangeFile = (event) => {
    if (doctorProfile?.status === "InActive") {
      return toast.error("Account Deactivated");
    }
    setAutomatedFile(event.target.files[0]);
    setIsOpen(true);
    // console.log("LOGG", event.target.files[0]);
  };

  const handlePatientDetail = (id) => {
    axios({
      method: "POST",
      url: `${BASE_URL}/patient/detail`,
      data: { patientId: id },
    })
      .then((response) => {
        console.log("Get Patient Detail", response);
        setPatientDetail(response?.data);
        setSelectPatient(true);
      })
      .catch((error) => {
        console.log("Get Patient Detail ERROR", error.response);
      });
  };

  // useEffect(() => {
  //   const loadAllPatient = async () => {
  //     const data = await axios({
  //       method: "GET",
  //       url: `${BASE_URL}//superadmin/hospitals`,
  //       // data: { doctorId: isAuth()?._id },
  //     })
  //       .then((response) => {
  //         console.log("Get pATIENT", response);
  //         setPatients(response.data);
  //       })
  //       .catch((error) => {
  //         console.log("Get Patient ERROR", error.response);
  //       });
  //   };
  //   loadAllPatient();
  // }, []);

  useEffect(() => {
    loadRecords();
  }, [patientDetail]);
  const loadRecords = () => {
    axios({
      method: "POST",
      url: `${BASE_URL}/reports/patient`,
      data: { patientId: patientDetail?._id },
    })
      .then((response) => {
        console.log("Get Patient Report", response);

        setGetPatientReport(response?.data);
      })
      .catch((error) => {
        console.log("Get Patient report ERROR", error.response);
      });
  };

  const handleAddPatient = () => {
    if (doctorProfile?.status === "InActive") {
      return toast.error("Account Deactivated");
    }

    const isNotDeleted = (task) => task.deleteForDoctor === false;
    const nonDeletedPatient = doctorPatient.filter(isNotDeleted);

    if (nonDeletedPatient?.length > 9) {
      return toast.error(
        "You can only have a maximum of 10 patients, please contact admin"
      );
    }
    navigate("/doctor/patients");
    setAddPatient(true);
  };

  const handlePreview = () => {
    window.location =
      "https://docs.google.com/viewerng/viewer?url=" +
      processedDocumentData?.file;
  };

  const handleDownload = () => {
    window.location = processedDocumentData?.file;
  };

  const handleChangeName = () => {
    axios({
      method: "PUT",
      url: `${BASE_URL}/doctor/edit-patient/${patientDetail?._id}`,
      data: { name: patientName },
    })
      .then((response) => {
        console.log("Get Patient Name ", response);

        setRefreshPatient(!refreshPatient);
        setEditPopup(false);
        // setGetPatientReport(response?.data);
      })
      .catch((error) => {
        console.log("Get Patient report ERROR", error.response);
      });
  };
  return (
    <DoctorSidebar activeLink="Dashboard">
      <div
        className={`${
          file || editPopup
            ? "bg-[rgba(0,0,0,0.7)] opacity-[.4] dark:opacity-[.3] overflow-hidden h-[90vh] z-auto "
            : "lightmode dark:bg-[#373737] dark:text-[#fff] min-h-fit"
        } p-[20px] `}
      >
        <h2 className="font-[500] text-[25px]">Dashboard</h2>
        <div className="mt-[20px] w-[100%] flex gap-[20px]">
          <div
            className={`${
              selectPatient ? "opacity-100" : "opacity-60"
            }  w-[25%] h-[250px] bg-[#fff] dark:bg-[var(--dark-primary)] rounded-xl`}
          >
            <div className="flex flex-col h-full justify-center items-center gap-[20px]">
              {selectPatient ? (
                <>
                  {/* <img
                    src={PatientImage}
                    className="w-[136px] h-[136px] rounded-full"
                    alt=""
                  /> */}
                  <FaRegUserCircle className="text-[100px]" />
                  {/* <input
                    type="text"
                    className="text-[20px] outline-none pl-9"
                    value={patientName}
                    onChange={(e) => setPatientName(e.target.value)}
                  /> */}
                  {/* <button onClick={handleChangeName}>Save</button> */}
                  <div className="flex items-center gap-2">
                    <p className="text-[20px]">{patientName}</p>
                    <MdEdit
                      className="cursor-pointer"
                      onClick={() => setEditPopup(true)}
                    />
                  </div>

                  <p>{patientDetail?.email}</p>
                </>
              ) : (
                <p>Select a Patient to view Detail</p>
              )}
            </div>
          </div>
          <div
            className={` ${
              selectPatient ? "opacity-100" : "opacity-60"
            }  w-[75%] h-[250px] bg-[#fff] dark:bg-[var(--dark-primary)] rounded-xl px-[30px] overflow-x-hidden `}
          >
            {selectPatient ? (
              <div className="w-[900px] flex items-center gap-[15px] mt-7  overflow-x-scroll whitespace-nowrap hScroll">
                {getPatientReport?.map((data, index) => {
                  return (
                    <>
                      {data?.deleteDoctor === false && (
                        <div
                          key={index}
                          title={data?.title}
                          className="font-inter px-2 min-w-[185px] h-[185px] bg-[#67A599] text-[#fff]  rounded-[17px] p-[15px] flex items-center justify-center flex-col mb-4 "
                        >
                          <PiNotepadBold size={55} />
                          {data?.title &&
                            (data?.title?.length > 16 ? (
                              <p>{data?.title?.slice(0, 15)} ...</p>
                            ) : (
                              <p>{data?.title}</p>
                            ))}

                          <span className="text-[13px] font-[500] mt-1  text-center">
                            {moment(new Date(data?.createdAt)).format(
                              "MMMM Do YYYY "
                            )}
                          </span>
                          <span className="text-[13px] font-[500] mt-1  text-center">
                            {moment(new Date(data?.createdAt)).format(
                              "h:mm:ss a "
                            )}
                          </span>
                          {data?.type === "file" ? (
                            <>
                              {" "}
                              <button
                                className="mt-[15px] w-[83px] h-[27px] text-[13px] bg-[#fff] rounded-full text-[var(--doctor-color)] font-[600]"
                                onClick={() =>
                                  (window.location =
                                    "https://docs.google.com/viewerng/viewer?url=" +
                                    data?.url)
                                }
                              >
                                Preview
                              </button>
                              <button
                                className="mt-[15px] w-[83px] h-[27px] text-[13px] bg-[#fff] rounded-full text-[var(--doctor-color)] font-[600]"
                                onClick={() => (window.location = data?.url)}
                              >
                                Download
                              </button>
                            </>
                          ) : (
                            <button
                              className="mt-[15px] w-[63px] h-[27px] text-[13px] bg-[#fff] rounded-full text-[var(--doctor-color)] font-[600]"
                              onClick={() =>
                                navigate(`/doctor/report/${data._id}`)
                              }
                            >
                              View
                            </button>
                          )}
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
            ) : (
              <div className="flex justify-center h-full items-center">
                Select a Patient to view Data
              </div>
            )}
          </div>
        </div>

        {/* <div>{text}</div> */}

        <div className="flex gap-[20px] my-[20px]">
          <div className="w-[25%] ">
            <div className="flex items-center mb-[10px] w-full h-[45px]  bg-[#fff] dark:bg-[var(--dark-primary)] rounded-xl relative text-[#CCE1DD]">
              <CiSearch className="absolute left-[10px]" />
              <input
                className="bg-transparent w-full outline-none ml-[35px] mr-[10px] placeholder-[#CCE1DD]"
                type="text"
                placeholder="Search Patient"
              />
            </div>
            <div className="h-[545px] rounded-xl bg-[#fff] dark:bg-[var(--dark-primary)] overflow-y-scroll whitespace-nowrap">
              <div className="flex justify-center item-center mt-6 mb-3">
                <button
                  onClick={handleAddPatient}
                  className=" mx-[25px] h-[46px] rounded-lg  text-[var(--doctor-color)] font-[500] w-full bg-[#FFCFAA]"
                >
                  + Add New Patient
                </button>
              </div>
              {doctorPatient?.map((data, index) => {
                return (
                  <>
                    {data?.deleteForDoctor === false && (
                      <div
                        key={index}
                        className={`${
                          data?._id == patientDetail?._id ? "" : ""
                        } flex cursor-pointer  item-center gap-[20px] border-b-[1px] border-[#77777780] py-[20px] mx-[35px] border-dotted `}
                        onClick={() => handlePatientDetail(data?._id)}
                      >
                        <input
                          type="radio"
                          value="Male"
                          name="gender"
                          checked={data?._id == patientDetail?._id}
                        />
                        <div className="relative">
                          <FaRegUserCircle className="text-[30px]" />
                          {/* <img
                          src={PatientImage}
                          className="w-[53px] h-[53px]"
                          alt=""
                        /> */}
                          {/* <div className="bg-[#00FF66] w-[7px] h-[6px] rounded-full absolute top-[2px] left-[39px]"></div> */}
                        </div>
                        <div className="">
                          <p className="text-[#000000] dark:text-[#fff] text-[15px] font-[500]">
                            {data?.name}
                          </p>
                          {/* <span className="text-[14px] text-[#000000] dark:text-[#FFCFAA] font-[400]">
                          last seen 3 mins ago
                        </span> */}
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          </div>
          <div
            className={`${
              selectPatient ? "opacity-100" : "opacity-60 cursor-not-allowed"
            }   w-[75%]  bg-[#fff] dark:bg-[var(--dark-primary)] rounded-xl flex justify-center items-center gap-[45px]`}
          >
            <Modal
              isOpen={modalIsOpen}
              //  onAfterOpen={afterOpenModal}
              onRequestClose={closeModal}
              shouldCloseOnOverlayClick={true}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <div className="p-8 text-black">
                <div className="flex flex-col items-center gap-[20px]">
                  <p className="font-inter text-[18px]">Upload Document</p>

                  {automatedFile && (
                    <>
                      <p>{automatedFile?.name}</p>
                      <div className="w-full flex flex-row items-center justify-center gap-6">
                        {!processedDocumentData ? (
                          <>
                            <button
                              disabled={!!loading}
                              className={` ${
                                loading ? "opacity-60" : "opacity-100"
                              } flex items-center justify-center bg-[var(--doctor-color)] text-white py-3 px-12 rounded-lg`}
                              onClick={() =>
                                handleDocumentProcessing("transcribe")
                              }
                            >
                              {!loading ? (
                                "Transcribe Only"
                              ) : (
                                <div className="flex flex-row">
                                  <img
                                    className="w-[24px] h-[24px] mr-2"
                                    src={require("../../images/loading.gif")}
                                  />
                                  Working ...
                                </div>
                              )}
                            </button>
                            <select
                              id="time"
                              className={`${
                                disableSelect
                                  ? ""
                                  : "opacity-100 cursor-pointer"
                              } outline-none bg-[var(--doctor-color)]  rounded-full h-[50px] text-white px-6 dark:bg-[var(--dark-primary)] text-[16px] w-[27%] `}
                              value={selectedLanguage}
                              // disabled={disableSelect}
                              onChange={(e) =>
                                setSelectedLanguage(e.target.value)
                              }
                            >
                              {language?.map((data, index) => {
                                return (
                                  <>
                                    <option
                                      value={data?.value}
                                      className="py-3 "
                                    >
                                      {data?.name}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                            <button
                              disabled={!!loading2 || !selectedLanguage}
                              className={` ${
                                loading2 || !selectedLanguage
                                  ? "opacity-60"
                                  : "opacity-100"
                              } flex items-center justify-center bg-[var(--doctor-color)] text-white py-3 px-12 rounded-lg`}
                              onClick={() =>
                                handleDocumentProcessing("translate")
                              }
                            >
                              {!loading2 ? (
                                "Transcribe + Translate"
                              ) : (
                                <div className="flex flex-row">
                                  <img
                                    className="w-[24px] h-[24px] mr-2"
                                    src={require("../../images/loading.gif")}
                                  />
                                  Working ...
                                </div>
                              )}
                            </button>
                          </>
                        ) : (
                          <>
                            {/* <button
                              className={` ${
                                loading ? "opacity-60" : "opacity-100"
                              } flex items-center justify-center bg-[var(--doctor-color)] text-white py-3 px-12 rounded-lg`}
                              onClick={handlePreview}
                            >
                              Preview
                            </button> */}
                            <button
                              className={` ${
                                loading ? "opacity-40" : "opacity-100"
                              } flex items-center justify-center bg-[var(--doctor-color)] text-white py-3 px-12 rounded-lg`}
                              onClick={handleDownload}
                            >
                              Download
                            </button>
                          </>
                        )}
                      </div>
                      <button
                        className="flex items-center justify-center bg-[#FF5252] text-white py-3 px-12 rounded-lg"
                        onClick={() => {
                          setAutomatedFile(null);
                          setProcessedDocumentData(false);
                          setIsOpen(false);
                        }}
                      >
                        Cancel
                      </button>
                    </>
                  )}
                </div>
              </div>
            </Modal>
            <div className="flex flex-col items-center gap-[20px]">
              <input
                type="file"
                id="automatedFile"
                style={{ display: "none" }}
                accept=".docx,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                onChange={handleChangeFile}
              />
              <button
                onClick={() => document.querySelector("#automatedFile").click()}
                disabled={!selectPatient}
              >
                <div className="w-[165px] h-[165px] bg-[#67A599] dark:bg-[#FFCFAA] text-[#fff] dark:text-[var(--dark-primary)] rounded-full flex justify-center items-center">
                  <PiUploadFill size={65} />
                  <div class="absolute inline-flex items-center justify-center w-12 h-6 text-xs font-[500] text-white bg-red-500 border-2 border-white rounded-[20px] ml-[80px] mb-[70px]  dark:border-gray-900">
                    Beta
                  </div>
                </div>
              </button>

              <p className="font-inter text-[18px]">Upload</p>
            </div>

            {/* <div className="flex flex-col items-center gap-[20px]"> 
              <input
                type="file"
                style={{ display: "none" }}
                accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf"
                onChange={handleChange}
              />
              <button
                onClick={() =>
                  document.querySelector('input[type="file"]').click()
                }
                disabled={!selectPatient}
              >
                <div className="w-[165px] h-[165px] bg-[#67A599] dark:bg-[#FFCFAA] text-[#fff] dark:text-[var(--dark-primary)] rounded-full flex justify-center items-center">
                  <PiUploadFill size={65} />
                </div>
              </button>

              <p>{file?.name}</p>

              <p className="font-inter text-[18px]">Upload (Manual)</p>
            </div>*/}
            <p className="text-[16px] italic">Or</p>
            <div className="flex flex-col items-center gap-[20px]">
              <Link
                to={`/doctor/transcribe/${patientDetail?._id}`}
                className={`${
                  selectPatient ? "" : "pointer-events-none"
                } w-[165px]  h-[165px] bg-[#67A599] dark:bg-[#FFCFAA] text-[#fff] dark:text-[var(--dark-primary)] rounded-full flex justify-center items-center`}
              >
                <RiEdit2Fill size={65} />
              </Link>
              <p className="font-inter text-[18px]">Enter Text</p>
            </div>
          </div>
        </div>
      </div>
      {file && (
        <>
          <div className="absolute top-[200px] bg-white w-[750px]  left-[34%] right-[30%] rounded-lg">
            <div>
              <div className="text-black p-[35px] mt-[15px] rounded-2xl">
                <h2 className="text-center text-2xl font-[600]">Your File</h2>
                <p className="text-center my-8">{file?.name}</p>
                <div className="w-full flex items-center justify-center gap-6">
                  <button
                    disabled={transCribeClick}
                    className={` ${
                      transCribeClick ? "opacity-40" : "opacity-100"
                    } flex items-center justify-center bg-[var(--doctor-color)] text-white py-3 px-12 rounded-lg`}
                    onClick={handletranscribe}
                  >
                    {transcribingText}
                  </button>
                  <button
                    className="flex items-center justify-center bg-[#FF5252] text-white py-3 px-12 rounded-lg"
                    onClick={() => setFile(null)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {editPopup && (
        <>
          <div className="w-[400px] h-[200px]  absolute top-[200px] left-[40%] right-[40%]">
            <button className="w-[550px] h-[60px] bg-[var(--doctor-color)] dark:bg-[#67A599] text-[21px] font-[500] text-[#fff] rounded-2xl">
              Edit Patient
            </button>
            <div className=" w-[550px] bg-[#CCE1DD] dark:bg-[var(--dark-primary)] p-[35px] mt-[15px] rounded-2xl">
              <input
                type="text"
                className="text-[#052721] border-[1px] pl-5 border-[rgba(0,0,0,.4)] w-full outline-none h-[45px] rounded-lg mb-7  "
                value={patientName}
                onChange={(e) => setPatientName(e.target.value)}
              />

              <div className="flex gap-[20px] mt-[30px] text-[21px] font-inter font-[700] text-[#fff]">
                <button
                  className="w-[65%] h-[53px] bg-[#DDBEA6] rounded-full"
                  onClick={handleChangeName}
                >
                  Save Patient
                </button>
                <button
                  className="w-[35%] h-[53px] bg-[#FF5252] rounded-full"
                  onClick={() => setEditPopup(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </DoctorSidebar>
  );
};

export default DoctorDashboardNew;
