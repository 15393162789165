import React, { useContext, useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { FaAngleDown } from "react-icons/fa6";
import axios from "axios";
import BASE_URL from "../../baseUrl";
import { loadStripe } from "@stripe/stripe-js";

import GlobalStorage from "../../Storage/ContextProvider";
import { toast } from "react-toastify";
const AddHospitalPopup = ({ setAddPaymentlActive }) => {
  // const [addPaymentlActive, setAddPaymentlActive] = useState(false);
  const [isCountryOpen, setIsCountryOpen] = useState(false);
  const [isStateOpen, setIsStateOpen] = useState(false);
  const [countries, setCoutries] = useState();
  const [states, setState] = useState([]);
  const [countryName, setCountryName] = useState("default");
  const [stateName, setStateName] = useState("default");
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState();
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [phone, setPhone] = useState("");
  const [hospitalName, setHospitalName] = useState("");
  const [noOfAccount, setNoOfAccount] = useState(1);

  const { planType, setPlanType, planDuration, setPlanDuration } =  
    useContext(GlobalStorage);
  const [planAmount, setPlanAmount] = useState(0);

  const handleClosePopup = () => {
    setAddPaymentlActive(false);
    setIsCountryOpen(false);
    setIsStateOpen(false);
  };
  useEffect(() => {
    if (countryName == "") {
      setCountryName("default");
    }
    loadCountry();
  }, [countryName]);

  const loadCountry = () => {
    axios({
      method: "GET",
      url: `${BASE_URL}/search/cs?country=${countryName}`,
    })
      .then((response) => {
        console.log("Get Coutries", response);
        // setHospitals(response.data);
        setCoutries(response.data);
      })
      .catch((error) => {
        console.log("Get Hospitals ERROR", error.response);
      });
  };

  useEffect(() => {
    if (stateName == "") {
      setStateName("default");
    }
    loadState();
  }, [stateName, selectedCountry]);

  const loadState = () => {
    axios({
      method: "GET",
      url: `${BASE_URL}/search/cs?country=${selectedCountry}&state=${stateName}`,
    })
      .then((response) => {
        console.log("Get States", response);
        // setHospitals(response.data);
        setState(response.data);
      })
      .catch((error) => {
        console.log("Get State ERROR", error.response);
      });
  };
  const handleSelectCountry = (data) => {
    setSelectedCountry(data);
    // setCountryName(data)
    setIsCountryOpen(false);
  };
  const handleSelectState = (data) => {
    setSelectedState(data);
    // setCountryName(data)
    setIsStateOpen(false);
  };
  const handleCountryFocus = () => {
    setIsCountryOpen(true);
    setSelectedCountry();
  };
  const handleStateFocus = () => {
    setIsStateOpen(true);
    setSelectedState();
  };

  useEffect(() => {
    console.log("Plann Duration", planDuration);
    if (planType === "Provider Pro" && planDuration == 12) {
      setPlanAmount(540);
    } else if (planType === "Free") {
      setPlanAmount(0);
    } else if (planType === "Provider Pro" && planDuration == 1) {
      setPlanAmount(50);
    }
  }, [planType, planDuration]);

  const handlePayment = async () => {
    if (planType === "Free") {
      const data = {
        fullName: ` ${firstName} ${lastName}`,
        email,
        phone,
        hospitalName,
        state: selectedState,
        country: selectedCountry,
        subscriptionType: planType,
        noOfAccount: 1,
      };

      const LOGINData = await axios({
        method: "POST",
        url: `${BASE_URL}/hospital/free-register`,
        data: data,
      })
        .then((response) => {
          console.log("Hospital register Success", response);
          toast.success(response?.data?.msg);
          setAddPaymentlActive(false);
        })
        .catch((error) => {
          console.log("Hospital Register  Error", error.response.data);
          toast.error(error.response.data);
        });
    } else if (planType === "Provider Pro") {
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY);
      const data = {
        planType: planType,
        amount: planAmount * noOfAccount,
        noOfMonths: planDuration,
        email,
        phone,
        hospitalName,
        state: selectedState,
        country: selectedCountry,
        subscriptionType: planType,
        noOfAccount,
      };
      const handleStripe = await axios({
        method: "POST",
        url: `${BASE_URL}/create-checkout-session`,
        data: data,
      })
        .then((response) => {
          console.log("Payment Redirect", response?.data);
          // const session = response.data.id;
          const { payment_url } = response.data;
          window.open(payment_url, "_blank");
          const redirectUrl = window.location.href;
          console.log("Redirtexd", redirectUrl);
          setAddPaymentlActive(false);

          // const result = stripe.redirectToCheckout({
          //   sessionId: response.data.id,
          //   // redirectUrl,
          // });

          // const checkoutUrl = stripe.redirectToCheckout({
          //   sessionId: session,
          //   redirectUrl,
          // });
          // window.open(`https://checkout.stripe.com/c/pay/${session}`, "_blank");
        })
        .catch((error) => {
          console.log("Payment Redirect Error ", error);
          toast.error(error.response.data);
        });
    }
  };

  return (
    <>
      <div className="flex justify-between items-start">
        <button
          className="text-[#101828] px-[22px] py-[10px] border-[#E4E7EC] border-[1px] rounded-lg text-[17px] font-inter font-[600]"
          style={{ boxShadow: " 0px 1px 2px 0px #1018280D" }}
        >
          {planType}
        </button>
        <RxCross2
          className="text-[#667085] cursor-pointer text-[24px]"
          onClick={handleClosePopup}
        />
      </div>

      <p className="text-[#475467] text-sm mt-4">
        Fill in the form to get started
      </p>
      <div className="font-inter font-[500] text-[#344054] text-sm mt-6">
        {planType === "Free" && (
          <>
            <div className=" my-4 flex items-center w-full gap-5">
              <div className="flex flex-col gap-2 w-[50%]">
                <label>First Name*</label>
                <input
                  type="text"
                  className="outline-none border-[1px] border-[#D0D5DD] px-[14px] py-[10px] w-full rounded-lg"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className="flex flex-col gap-2 w-[50%]">
                <label>Last Name*</label>
                <input
                  type="text"
                  className="outline-none border-[1px] border-[#D0D5DD] px-[14px] py-[10px] w-full rounded-lg"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>
          </>
        )}

        <div className="flex flex-col gap-2 my-4">
          <label>Hospital Name *</label>
          <input
            type="text"
            className="outline-none border-[1px] border-[#D0D5DD] px-[14px] py-[10px] w-full rounded-lg"
            placeholder="Hospital Name"
            value={hospitalName}
            onChange={(e) => setHospitalName(e.target.value)}
          />
        </div>
        <div className=" my-4 flex items-center w-full gap-5">
          <div className="flex flex-col gap-2 w-[50%] relative">
            <label>Country*</label>
            <div className="">
              <div className="border-[1px] border-[#D0D5DD] px-[14px] py-[10px] w-full rounded-lg relative">
                <input
                  type="text"
                  className="outline-none w-[80%] "
                  placeholder="Country"
                  onFocus={handleCountryFocus}
                  value={selectedCountry}
                  onChange={(e) => setCountryName(e.target.value)}
                />
                {isCountryOpen ? (
                  <RxCross2
                    className="absolute  top-4 left-[95%] text-[#667085] cursor-pointer"
                    onClick={() => setIsCountryOpen(false)}
                  />
                ) : (
                  <FaAngleDown
                    className="absolute  top-4 left-[95%] text-[#667085] cursor-pointer"
                    onClick={() => setIsCountryOpen(true)}
                  />
                )}
              </div>
            </div>

            {isCountryOpen && (
              <>
                <div className=" absolute top-[100%]  overflow-y-scroll  h-[200px] bg-[white] text-black w-[95%]">
                  {countries?.map((data, index) => {
                    return (
                      <>
                        <p
                          className="ml-6 mt-3 cursor-pointer"
                          onClick={() => handleSelectCountry(data)}
                        >
                          {data}
                        </p>
                      </>
                    );
                  })}
                </div>
              </>
            )}
          </div>
          <div className="flex flex-col gap-2 w-[50%] relative">
            <label>Region/State/province*</label>
            <div className="">
              <div className="border-[1px] border-[#D0D5DD] px-[14px] py-[10px] w-full rounded-lg relative">
                <input
                  type="text"
                  className="outline-none w-[80%] "
                  placeholder="Region/State/province"
                  onFocus={handleStateFocus}
                  value={selectedState}
                  onChange={(e) => setStateName(e.target.value)}
                />
                {isStateOpen ? (
                  <RxCross2
                    className="absolute  top-4 left-[95%] text-[#667085] cursor-pointer"
                    onClick={() => setIsStateOpen(false)}
                  />
                ) : (
                  <FaAngleDown
                    className="absolute  top-4 left-[95%] text-[#667085] cursor-pointer"
                    onClick={() => setIsStateOpen(true)}
                  />
                )}
              </div>
            </div>

            {isStateOpen && (
              <>
                <div className=" absolute top-[100%]  overflow-y-scroll right-1 max-h-[200px] bg-[white] text-black w-[95%]">
                  {states == "" && (
                    <>
                      <p className="text-center h-[70px] flex justify-center items-center">
                        No States Found
                      </p>
                    </>
                  )}
                  {states &&
                    states?.map((data, index) => {
                      return (
                        <>
                          <p
                            className="ml-6 mt-3 cursor-pointer"
                            onClick={() => handleSelectState(data)}
                          >
                            {data}
                          </p>
                        </>
                      );
                    })}
                </div>
              </>
            )}
          </div>
        </div>

        <div className=" my-4 flex items-center w-full gap-5">
          <div className="flex flex-col gap-2 w-[50%]">
            <label>Email Address*</label>
            <input
              type="text"
              className="outline-none border-[1px] border-[#D0D5DD] px-[14px] py-[10px] w-full rounded-lg"
              placeholder="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="flex flex-col gap-2 w-[50%]">
            <label>Phone Number*</label>
            <input
              type="text"
              className="outline-none border-[1px] border-[#D0D5DD] px-[14px] py-[10px] w-full rounded-lg"
              placeholder="Phone Number "
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
        </div>
        {planType === "Provider Pro" && (
          <>
            <div className="flex items-center justify-between gap-2 my-4">
              <div className="flex items-center  gap-2 my-4">
                <label>Number Of Accounts:</label>
                <input
                  type="number"
                  className="outline-none border-[1px] border-[#D0D5DD] px-[14px] py-[10px] w-[110px] rounded-lg"
                  // placeholder="Phone Number "
                  min="1"
                  value={noOfAccount}
                  onChange={(e) => {
                    if (e.target.value < 0) {
                      e.target.value = 1;
                    }
                    setNoOfAccount(e.target.value);
                  }}
                  // onChange={(e) => setNoOfAccount(e.target.value)}
                />
              </div>
              <div>
                <p className="mb-4">Due Now:</p>
                <p className="text-[50px] font-inter font-[700]">
                  ${planAmount * noOfAccount}
                </p>
              </div>
            </div>
          </>
        )}
      </div>

      <div className="flex items-center gap-5 mt-8 mb-4">
        <button
          className="w-[50%] text-[#101828]  py-[10px] border-[#E4E7EC] border-[1px] rounded-lg text-[17px] font-inter font-[600]"
          onClick={handleClosePopup}
        >
          Cancel
        </button>

        <button
          className="w-[50%] text-[#fff] bg-[var(--doctor-color)]  py-[10px] border-[#E4E7EC] border-[1px] rounded-lg text-[17px] font-inter font-[600]"
          onClick={handlePayment}
        >
          Confirm
        </button>
      </div>
    </>
  );
};

export default AddHospitalPopup;
