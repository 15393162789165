import React, { useContext, useEffect, useRef, useState } from "react";
import Header from "../../Component/Patient/Header";
import HeroImg from "../../images/patient/hero.jpeg";
import HeroBlur from "../../images/patient/heroBlur.png";
import { TfiArrowCircleDown } from "react-icons/tfi";
import Feature1 from "../../images/patient/feature4.png";
import Feature3 from "../../images/patient/feature5.png";
import Feature2 from "../../images/patient/feature6.png";
import DemoImg from "../../images/patient/demo.png";
import Drawer from "../../images/patient/desk-drawer.png";
import Internet from "../../images/patient/wifi.png";
import Mug from "../../images/patient/mug.png";
import { FaCheck } from "react-icons/fa6";
import { IoIosArrowUp } from "react-icons/io";
import LatestUpdate from "../../Component/Patient/LatestUpdate";
import Footer from "../../Component/Patient/Footer";
import GlobalStorage from "../../Storage/ContextProvider";
import TestimonialHome from "../../Component/Patient/Testimonials/TestimonialHome";
import Blog1 from "../../images/patient/blog1.png";
import RequestDemo from "../../Component/Patient/RequestDemo";
import { useNavigate } from "react-router-dom";

const PatientHome = () => {
  const [activeFaq, setActiveFaq] = useState(0);
  const {
    isOpen,
    setIsOpen,
    isRequestDemo,
    setRequestDemo,
    toggleProduct,
    setToggleProduct,
  } = useContext(GlobalStorage);
  const navigate = useNavigate();
  const sectionRef = useRef(null);

  const handleScollProduct = () => {
    navigate("/patient");
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (window.location.hash === "#sectionRef") {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
    // Add more conditions for other sections if needed
  }, [toggleProduct]);


  const handleHomeButton = () => {
    navigate("/#sectionRef");
    setToggleProduct(!toggleProduct);
  };
  return (
    <>
      <Header />
      {!isOpen && (
        <>
          <div
            className={`${
              isRequestDemo
                ? "opacity-40 max-h-[88vh] overflow-y-hidden"
                : "opacity-100 h-full"
            }`}
          >
            <div
              className={`${
                isRequestDemo ? "opacity:100" : "opacity-100"
              } h-[88vh] w-full flex flex-col justify-center items-center relative bg-[#373737] text-white`}
              style={{
                backgroundImage: `url(${HeroBlur})`,
                boxShadow: "0px 14px 35px 0px #0000004A",
                backgroundSize:"cover",
                backgroundPosition: "center, center",
                backgroundRepeat:"no-repeat"
              }}
            >
              <p className="text-[#68D585] text-sm ">
                YOUR HEALTH AT YOUR FINGERTIPS
              </p>
              <h1 className="900px:text-[40px] 1100px:text-[50px] 1300px:text-[60px] text-center text-[30px] ">
                Increase consultation efficiency and <br /> health information
                literacy
              </h1>

              <button
                className="w-[250px] h-[50px] bg-[#FFCFAA] mt-[25px] text-[var(--doctor-color)] font-inter font-[600] rounded-lg"
                onClick={() => setRequestDemo(true)}
              >
                Book a Demo
              </button>
              <div className="absolute bottom-[30px]"
              onClick={handleHomeButton}>
                <TfiArrowCircleDown size={40} />
              </div>
            </div>

            <div className="bg-[#CCE1DD] min-h-[60vh] w-full">
              <div className="flex  justify-center 1100px:px-[100px] 1300px:px-[200px] px-[50px] py-[50px] ">
                <div className=" w-full flex  justify-center items-center 900px:flex-row flex-col">
                  <div className="font-inter text-[#161C2D] 900px:w-[32%] w-ful">
                    <h1 className=" 600px:text-[48px] text-[35px] text-center font-[700]">
                      60
                    </h1>
                    <p className="text-[19px] text-center opacity-70">
                      Seconds to simplify and translate information
                    </p>
                  </div>

                  <div className="font-inter text-[#161C2D] 900px:w-[32%] w-full">
                    <h1 className=" 600px:text-[48px] text-[35px] text-center font-[700]">
                      10+
                    </h1>
                    <p className="text-[19px] text-center opacity-70">
                      Languages supported with more being added
                    </p>
                  </div>

                  <div className="font-inter text-[#161C2D] 900px:w-[32%] w-full">
                    <h1 className=" 600px:text-[48px] text-[35px] text-center font-[700]">
                      98%
                    </h1>
                    <p className="text-[19px] text-center opacity-70">
                      Patient satisfaction with medical information received
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="900px:px-[80px] 1300px:px-[200px] px-[50px] bg-[#CCE1DD] text-[#161C2D] font-inter"
              ref={sectionRef}
              id="productHome"
            >
              <h2 className=" font-[700] 600px:text-[48px] text-[35px] text-center ">
                Our Features
              </h2>
              <div className="flex justify-center 600px:items-center 600px:flex-row flex-col gap-8 py-9">
                {/* <p>
                With lots of unique blocks, you can easily <br /> build a page
                easily without any coding.{" "}
              </p> */}
                <button
                  className="bg-[#FFCFAA] w-[260px] h-[50px] rounded-[10px] text-[var(--doctor-color)] font-[600]"
                  onClick={() => setRequestDemo(true)}
                >
                  Book a demo session
                </button>
              </div>

              <div className="font-inter text-[#161C2D] flex   900px:flex-row flex-col 900px:items-start  items-center mt-[40px] pb-[70px] gap-6">
                <div className="flex flex-col   500px:w-[70%] w-[90%] 900px:w-[30%]">
                  <img src={Feature1} className="w-[100%] h-auto" alt="" />
                  <h3 className="font-[700] text-[24px] mt-5 text-center ">
                    Simplify and Translate
                  </h3>
                  <p className="font-[400] text-[17px] text-center">
                    Simplify and translate medical information
                  </p>
                </div>
                <div className="flex flex-col   500px:w-[70%] w-[90%] 900px:w-[30%]">
                  <img src={Feature3} className="w-[100%] h-auto" alt="" />
                  <h3 className="font-[700] text-[24px] mt-5 text-center ">Easy to use</h3>
                  <p className="font-[400] text-[17px] text-center">
                    Enter note, simplify and/or translate in 60 seconds
                  </p>
                </div>
                <div className="flex flex-col  500px:w-[70%] w-[90%] 900px:w-[30%]">
                  <img src={Feature2} className="w-[100%] h-auto" alt="" />
                  <h3 className="font-[700] text-[24px] mt-5 text-center">Easy sharing</h3>
                  <p className="font-[400] text-[17px] text-center">
                    Share seamlessly via our patient portal
                  </p>
                </div>
              </div>
            </div>

            {/* <div className="bg-white 900px:px-[100px] px-[50px] 1300px:px-[200px] py-[100px] flex 900px:flex-row flex-col font-inter text-[#161C2D] items-center gap-5 ">
            <img
              src={DemoImg}
              className="900px:w-[60%]  1300px:w-[100%] h-auto mb-5"
              alt=""
            />
            <div>
              <h3 className=" 600px:text-[48px] text-[35px] font-[600] leading-tight -mb-[8px] ">
                Improve patient communication with ease
              </h3>
             }
              <p className="mt-[15px] opacity-70">
                Easy to use, easy to share. AI Powered simplification and
                translation for better patient communication
              </p>
            </div>
          </div> */}

            {/* <div className="bg-[#CCE1DD] 900px:px-[100px] 1300px:px-[180px] px-[50px] font-inter py-[100px] text-[#161C2D]">
            <h3 className="text-center font-inter font-[700] text-[#161C2D] text-[45px] mb-9">
              Latest Blog Post
            </h3>
           
            <div>
              <div className="flex items-center 900px:flex-row flex-col gap-11 my-8">
                <div className="900px:w-[50%] w-[100%] flex 500px:flex-row flex-col items-center gap-4 font-inter text-[#161C2D]">
                  <img src={Blog1} className="500px:w-[170px] 500px:h-[225px] w-[100%] h-auto" alt="" />
                  <div>
                    <h2 className="font-[700] text-[20px]">Dedicated Desk</h2>
                    <p className="text-[15px] mt-2">
                      Lorem ipsum dolor sit amet consectetur. Dapibus auctor
                      faucibus pellentesque orci vitae sit dignissim mauris.
                      Orci tristique faucibus non pellentesque id est morbi sed.
                      Sed amet eget eget sed massa ut amet urna mi. Nisl pretium
                      morbi tristique pulvinar ultricies aliquet. Blandit ac.
                    </p>
                    <button className="bg-[#FFCFAA] py-[10px] px-[30px] font-[600] text-[var(--doctor-color)] rounded-md mt-3 ">
                      Read More
                    </button>
                  </div>
                </div>

                <div className="900px:w-[50%] w-[100%] 500px:flex-row flex-col flex items-center gap-4 font-inter text-[#161C2D]">
                  <img src={Blog1} className="500px:w-[170px] 500px:h-[225px] w-[100%] h-auto" alt="" />
                  <div>
                    <h2 className="font-[700] text-[20px]">Dedicated Desk</h2>
                    <p className="text-[15px] mt-2">
                      Lorem ipsum dolor sit amet consectetur. Dapibus auctor
                      faucibus pellentesque orci vitae sit dignissim mauris.
                      Orci tristique faucibus non pellentesque id est morbi sed.
                      Sed amet eget eget sed massa ut amet urna mi. Nisl pretium
                      morbi tristique pulvinar ultricies aliquet. Blandit ac.
                    </p>
                    <button className="bg-[#FFCFAA] py-[10px] px-[30px] font-[600] text-[var(--doctor-color)] rounded-md mt-3 ">
                      Read More
                    </button>
                  </div>
                </div>
              </div>

              <div className="flex items-center 900px:flex-row flex-col gap-11 my-8">
                <div className="900px:w-[50%] w-[100%] flex 500px:flex-row flex-col items-center gap-4 font-inter text-[#161C2D]">
                  <img src={Blog1} className="500px:w-[170px] 500px:h-[225px] w-[100%] h-auto" alt="" />
                  <div>
                    <h2 className="font-[700] text-[20px]">Dedicated Desk</h2>
                    <p className="text-[15px] mt-2">
                      Lorem ipsum dolor sit amet consectetur. Dapibus auctor
                      faucibus pellentesque orci vitae sit dignissim mauris.
                      Orci tristique faucibus non pellentesque id est morbi sed.
                      Sed amet eget eget sed massa ut amet urna mi. Nisl pretium
                      morbi tristique pulvinar ultricies aliquet. Blandit ac.
                    </p>
                    <button className="bg-[#FFCFAA] py-[10px] px-[30px] font-[600] text-[var(--doctor-color)] rounded-md mt-3 ">
                      Read More
                    </button>
                  </div>
                </div>

                <div className="900px:w-[50%] w-[100%] flex 500px:flex-row flex-col items-center gap-4 font-inter text-[#161C2D]">
                  <img src={Blog1} className="500px:w-[170px] 500px:h-[225px] w-[100%] h-auto" alt="" />
                  <div>
                    <h2 className="font-[700] text-[20px]">Dedicated Desk</h2>
                    <p className="text-[15px] mt-2">
                      Lorem ipsum dolor sit amet consectetur. Dapibus auctor
                      faucibus pellentesque orci vitae sit dignissim mauris.
                      Orci tristique faucibus non pellentesque id est morbi sed.
                      Sed amet eget eget sed massa ut amet urna mi. Nisl pretium
                      morbi tristique pulvinar ultricies aliquet. Blandit ac.
                    </p>
                    <button className="bg-[#FFCFAA] py-[10px] px-[30px] font-[600] text-[var(--doctor-color)] rounded-md mt-3 ">
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

            <TestimonialHome />
            {/* 
          <div className="bg-[var(--doctor-color)] 900px:px-[100px] 1300px:px-[200px] py-[100px] px-[30px] font-inter text-white ">
            <div className="w-full flex  900px:flex-row flex-col 900px:gap-5 gap-10">
              <div className="900px:w-[50%] w-full">
                <div>
                  <h2 className="font-[700] 600px:text-[48px] text-[35px] leading-tight">
                    We are always here for your backup.
                  </h2>
                  <p className="mt-[15px] opacity-65">
                    We share common trends and strategies for creating &
                    improving your rental income.
                  </p>
                  <div className="flex items-start gap-3 mt-[20px]">
                    <div className="w-[30px] h-[30px] rounded-full bg-[#68D585] flex items-center justify-center mt-1 ">
                      <FaCheck className="text-white" size={20} />
                    </div>
                    <div>
                      <h2 className="font-[700] text-[21px]">
                        Noice Free Locations
                      </h2>
                      <p className="mt-[12px] opacity-65 text-[17px]">
                        With lots of unique blocks, you can easily build a page
                        without coding.{" "}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="flex items-start gap-3 mt-[40px]">
                  <div className="w-[30px] h-[30px] rounded-full bg-[#68D585] flex items-center justify-center mt-1 ">
                    <FaCheck className="text-white" size={20} />
                  </div>
                  <div>
                    <h2 className="font-[700] text-[21px]">
                      24/7 Hour Support{" "}
                    </h2>
                    <p className="mt-[12px] opacity-65 text-[17px]">
                      With lots of unique blocks, you can easily build a page
                      without coding.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="900px:w-[50%] w-full">
                <div className="bg-white rounded-md p-6">
                  <div className="text-[var(--doctor-color)] border-b pt-4 pb-4">
                    <div
                      className="flex items-center justify-between cursor-pointer"
                      onClick={() => setActiveFaq(0)}
                    >
                      <h2 className="text-[var(--doctor-color)] font-[700] 600px:text-[21px] text-[18px]">
                        How do I setup my portal?{" "}
                      </h2>
                      {activeFaq === 0 ? (
                        <IoIosArrowUp
                          className="text-[var(--doctor-color)] "
                          size={21}
                        />
                      ) : (
                        <IoIosArrowUp
                          className="text-[var(--doctor-color)] rotate-90"
                          size={21}
                        />
                      )}
                    </div>
                    {activeFaq === 0 && (
                      <p className="opacity-70 mt-[15px]">
                        With lots of unique blocks, you can easily build a page
                        with coding. Build your next landing page. Integer ut
                        obe ryn. Sed feugiat vitae turpis a porta.
                      </p>
                    )}
                  </div>

                  <div className="text-[var(--doctor-color)] border-b pt-4 pb-4">
                    <div
                      className="flex items-center justify-between cursor-pointer"
                      onClick={() => setActiveFaq(1)}
                    >
                      <h2 className="text-[var(--doctor-color)] font-[700] 600px:text-[21px] text-[18px] w-[80%]">
                        Can I use translate to any language on the portal?
                      </h2>
                      {activeFaq === 1 ? (
                        <IoIosArrowUp
                          className="text-[var(--doctor-color)] "
                          size={21}
                        />
                      ) : (
                        <IoIosArrowUp
                          className="text-[var(--doctor-color)] rotate-90"
                          size={21}
                        />
                      )}
                    </div>
                    {activeFaq === 1 && (
                      <p className="opacity-70 mt-[15px]">
                        With lots of unique blocks, you can easily build a page
                        with coding. Build your next landing page. Integer ut
                        obe ryn. Sed feugiat vitae turpis a porta.
                      </p>
                    )}
                  </div>

                  <div className="text-[var(--doctor-color)] border-b pt-4 pb-4">
                    <div
                      className="flex items-center justify-between cursor-pointer"
                      onClick={() => setActiveFaq(2)}
                    >
                      <h2 className="text-[var(--doctor-color)] font-[700] 600px:text-[21px] text-[18px] w-[80%]">
                        How often do you release update?
                      </h2>
                      {activeFaq === 2 ? (
                        <IoIosArrowUp
                          className="text-[var(--doctor-color)] "
                          size={21}
                        />
                      ) : (
                        <IoIosArrowUp
                          className="text-[var(--doctor-color)] rotate-90"
                          size={21}
                        />
                      )}
                    </div>
                    {activeFaq === 2 && (
                      <p className="opacity-70 mt-[15px]">
                        With lots of unique blocks, you can easily build a page
                        with coding. Build your next landing page. Integer ut
                        obe ryn. Sed feugiat vitae turpis a porta.
                      </p>
                    )}
                  </div>

                  <div className="text-[var(--doctor-color)] border-b pt-4 pb-4">
                    <div
                      className="flex items-center justify-between cursor-pointer"
                      onClick={() => setActiveFaq(3)}
                    >
                      <h2 className="text-[var(--doctor-color)] font-[700] 600px:text-[21px] text-[18px] w-[80%]">
                        How can I access to old version?
                      </h2>
                      {activeFaq === 3 ? (
                        <IoIosArrowUp
                          className="text-[var(--doctor-color)] "
                          size={21}
                        />
                      ) : (
                        <IoIosArrowUp
                          className="text-[var(--doctor-color)] rotate-90"
                          size={21}
                        />
                      )}
                    </div>
                    {activeFaq === 3 && (
                      <p className="opacity-70 mt-[15px]">
                        With lots of unique blocks, you can easily build a page
                        with coding. Build your next landing page. Integer ut
                        obe ryn. Sed feugiat vitae turpis a porta.
                      </p>
                    )}
                  </div>

                  <div className="flex justify-end mt-6 ">
                    <button className="bg-[#FFCFAA] text-[var(--doctor-color)] w-[130px] h-[50px] font-[600] rounded-md">
                      View All
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

            <LatestUpdate />
            <Footer />
          </div>

          {isRequestDemo && (
            <>
              <div className="w-full">
                <div className="absolute top-[23%]  500px:w-[60%] w-[90%]  bg-[#fff] 500px:left-[18%] left-[20px] right-[40px] rounded-[12px] p-5 font-inter">
                  <RequestDemo />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default PatientHome;