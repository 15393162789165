import React, { useEffect } from "react";
import TestmonialBg from "../../images/landing/TestimonialBackground.png";
import { FaStar } from "react-icons/fa6";
import { Link, useLocation } from "react-router-dom";

const TestimonialSmall = () => {

  return (
    <>
      <div
        className=" px-[100px] max-[750px]:px-[40px] max-[1024px]:px-[70px] font-poppins py-[50px] bg-no-repeat bg-cover   "
        style={{
          backgroundImage: `url(${TestmonialBg})`,
        }}
      >
        <h2 className="text-[30px] leading-[45px] text-center  font-semibold">
          Testimonials
        </h2>

        <div className="flex justify-between items-center mt-7 max-[750px]:flex-col max-[750px]:gap-4 ">
          <div
            className="w-[30%] max-[1024px]:w-[32%]  max-[750px]:w-full  bg-primary text-[rgba(255,255,255,0.9)] border border-[#EFEBEB80] p-[25px] max-[1024px]:p-[16px] rounded-[30px] rounded-tl-none"
            style={{
              boxShadow: "0px 0px 17.3px 0px #00000026",
              backdropFilter: "blur(14.800000190734863px)",
            }}
          >
            <h2
              className=" mt-6 font-bold text-[20px] max-[1024px]:text-[17px] leading-[22px] bg-clip-text text-transparent mb-1 "
              style={{
                backgroundImage:
                  "linear-gradient(270deg, #F34E4E 0%, #BAEDBD 100%)",
              }}
            >
              Brendan Wylie-Toal
            </h2>
            <p className="font-semibold text-[14px] max-[1024px]:text-[13px] leading-[22px] my-1">
              Director of Innovation and Research
            </p>
            <p className="font-[500] text-[14px] leading-[22px]">KIDSABILITY</p>
          </div>

          <div
            className="w-[30%] max-[750px]:w-full max-[1024px]:w-[32%]  bg-primary text-[rgba(255,255,255,0.9)] border border-[#EFEBEB80] p-[25px] max-[1024px]:p-[16px] rounded-[30px] rounded-tl-none"
            style={{
              boxShadow: "0px 0px 17.3px 0px #00000026",
              backdropFilter: "blur(14.800000190734863px)",
            }}
          >
            <h2
              className=" mt-6 font-bold text-[20px]  max-[1024px]:text-[17px] leading-[22px] bg-clip-text text-transparent mb-1 "
              style={{
                backgroundImage:
                  "linear-gradient(270deg, #F34E4E 0%, #BAEDBD 100%)",
              }}
            >
              Brendan Wylie-Toal
            </h2>
            <p className="font-semibold text-[14px] max-[1024px]:text-[13px] leading-[22px] my-1">
              Director of Innovation and Research
            </p>
            <p className="font-[500] text-[14px] leading-[22px]">KIDSABILITY</p>
          </div>

          <div
            className="w-[30%] max-[750px]:w-full max-[1024px]:w-[32%]  bg-primary text-[rgba(255,255,255,0.9)] border border-[#EFEBEB80] p-[25px] max-[1024px]:p-[16px] rounded-[30px] rounded-tl-none"
            style={{
              boxShadow: "0px 0px 17.3px 0px #00000026",
              backdropFilter: "blur(14.800000190734863px)",
            }}
          >
            <h2
              className=" mt-6 font-bold text-[20px] max-[1024px]:text-[17px]  leading-[22px] bg-clip-text text-transparent mb-1 "
              style={{
                backgroundImage:
                  "linear-gradient(270deg, #F34E4E 0%, #BAEDBD 100%)",
              }}
            >
              Brendan Wylie-Toal
            </h2>
            <p className="font-semibold text-[14px] max-[1024px]:text-[13px]  leading-[22px] my-1">
              Director of Innovation and Research
            </p>
            <p className="font-[500] text-[14px] leading-[22px]">KIDSABILITY</p>
          </div>
        </div>

        <div className="flex w-full justify-center flex-col items-center gap-4 mt-6">
          <h2 className="text-[24px] max-[750px]:text-[20px] leading-[36px] max-[750px]:leading-[30px] font-semibold ">
            Read what our providers are saying
          </h2>

          <div className="flex items-center gap-4">
            <FaStar className="text-[#FFAE3C]" size={33} />
            <FaStar className="text-[#FFAE3C]" size={33} />
            <FaStar className="text-[#FFAE3C]" size={33} />
            <FaStar className="text-[#FFAE3C]" size={33} />
            <FaStar className="text-[#FFAE3C]" size={33} />
          </div>

          <Link to="/#testimonials" className="text-[22px] leading-[33px] underline ">
            view testimonials
          </Link>
        </div>
      </div>
    </>
  );
};

export default TestimonialSmall;
